<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  name: 'GlueNewsletterFooterSignup',

  props: {
    backgroundColor: {
      type: String,
    },
    inputBackgroundColor: {
      type: String,
    },
    inputColor: {
      type: String,
    },
    buttonBackgroundColor: {
      type: String,
    },
    buttonColor: {
      type: String,
    },
  },

  data() {
    return {
      show: true,
      formName: 'page_email_signup',
      complete: false,
      error: null,
      agreeToTerms: false,
      email: '',
      showTerms: false,
    }
  },
  computed: {
    formValid() {
      return this.agreeToTerms && this.email.length > 0
    },

    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
  },
  mounted() {
    const { snakeCase } = useStrings
    if (this.currentPage && Object.prototype.hasOwnProperty.call(this.currentPage, 'title'))
      this.formName = `page_${snakeCase(this.currentPage.title)}_email_signup`

    // TODO: Fix bus system
    // window.bus.$on('sapphire-replacement', () => {
    //   this.show = false
    // })
  },
  methods: {
    submitForm() {
      if (this.currentPage.site_id === 1 && window.dataLayer) {
        window.dataLayer.push({
          event: 'e_newsletterSignup',
        })
      }

      const data = {
        site_id: 1,
        form: this.formName,
        agree_to_terms: this.agreeToTerms ? 1 : 0,
        first_name: '',
        last_name: 'lastName',
        email: this.email,
        data: {},
      }

      $voixFetch('/api/form-signup', { method: 'post', body: data }).then(() => {
        this.complete = true
      }).catch((e) => {
        const { errors } = e.response.data
        console.error(errors)
      })
    },
  },
}
</script>

<template>
  <div v-if="show" class=" bg-orange-50 h-auto md:h-96 pt-20 mb-0 md:mb-32">
    <div
      class="relative tracking-footer-sign-up bg-glueblue-700 text-white text-center flex flex-col items-center pt-16 pb-8 px-8 md:p-12 mb-20 mx-auto"
      :class="{
        'bg-glueblue-700': !backgroundColor,
      }"
      :style="{
        backgroundColor,
      }"
      style="max-width: 1248px"
    >
      <div
        class="text-4xl font-sans font-light uppercase leading-tight tracking-wider mb-6 md:mb-2"
      >
        Be the first to know
      </div>
      <div
        class="font-sans text-sm uppercase tracking-widest mb-12 md:mb-8 mx-6 md:mx-0"
      >
        SIGN UP TO RECEIVE ATLANTIS NEWSLETTERS AND OFFERS IN YOUR INBOX
      </div>
      <form v-if="!complete" @submit.prevent="submitForm">
        <div class="w-full flex justify-center mb-6">
          <div class="flex w-full md:w-3/5">
            <ClientOnly>
              <input
                id="footer-signup-email"
                v-model="email"
                type="text"
                class="placholder-white text-xs leading-none font-sans2 w-full px-6 py-3 placeholder:text-white/50"
                :class="{
                  'bg-glueblue-600': !inputColor,
                }"
                :style="{
                  backgroundColor: inputBackgroundColor,
                  color: inputColor,
                }"
                name="email"
                placeholder="Enter Email Address"
              >
            </ClientOnly>
            <input
              type="submit"
              class="bg-glueblue-400 text-xs font-bold tracking-widest hidden uppercase font-sans px-8 leading-none md:block"
              style="height: 51px; width: 184px"
              value="Send me offers"
              :class="{
                'cursor-pointer': formValid,
                'opacity-75 cursor-not-allowed': !formValid,
                'bg-glueblue-400': !buttonColor,
              }"
              :style="{
                backgroundColor: buttonBackgroundColor,
                color: buttonColor,
              }"
              :disabled="!formValid"
            >
          </div>
        </div>

        <div>
          <div class="flex flex-col justify-center items-center mb-8">
            <label
              for="footer-newsletter-agree-to-terms"
              class="font-sans2 overflow-hidden flex items-center pb-2"
            >
              <div
                class=" w-6 h-6 mr-2 cursor-pointer flex justify-center items-center"
                :class="{
                  'bg-glueblue-600': !inputColor,
                }"
                :style="{
                  backgroundColor: inputBackgroundColor,
                  color: inputColor,
                }"
                @click="agreeToTerms = !agreeToTerms"
              >
                <Icon v-if="agreeToTerms" name="heroicons:check" class="w-8 h-8" />
              </div>

              <div class="text-left md:text-center">
                I am over the age of 18 and have read the
                <a
                  href="https://www.atlantisbahamas.com/privacy "
                  class="border-b-2  pb-1 text-white"
                  :class="{
                    'border-glueblue-400': !buttonColor,
                  }"
                  :style="{
                    borderColor: buttonBackgroundColor,
                  }"
                >
                  Privacy Policy
                </a>
              </div>
            </label>
            <div class="w-full md:w-1/2 text-xs font-sans2 opacity-50 mt-4">
              By submitting this form, I confirm I would like to receive emails on
              promotions, special offers and updates from Atlantis Paradise Island and its
              affiliated brands.
            </div>
          </div>

          <input
            type="submit"
            class="bg-glueblue-400 cursor-pointer text-xs font-bold tracking-widest uppercase w-full font-sans px-8 py-4 leading-none md:hidden"
            value="Send me offers"
            :class="{ 'opacity-75 cursor-not-allowed': !formValid, 'bg-glueblue-600': !inputColor }"

            :style="{
              backgroundColor: inputBackgroundColor,
              color: inputColor,
            }"
            :disabled="!formValid"
            @click.prevent="submitForm"
          >
        </div>
      </form>

      <div v-else class="text-xl font-sans uppercase">
        Thank you, we have added you to our newsletter
      </div>
    </div>
  </div>
</template>
